<template>
  <div v-if="isVisible" class="overlay" @click.self="close" >
    <div class="modal-content">
      <div class="lottery-dialog-box">
        <div class="close" @click="close"></div>
        <div class="title">
          <img src="../img/give-points-dialog.png" alt="" />
          <span>兑换记录</span>
        </div>

        <div class="table-box">
          <div class="table-title">
            <div>兑换时间</div>
            <div>兑换商品</div>
            <div>积分变动</div>
            <div>商品类型</div>
            <div>兑换状态</div>
            <div>物流信息</div>
            <div>操作</div>
          </div>
          <div class="scroll-tabs">
            <div
              class="table-content table-title"
              v-for="(item, index) in recodesArr"
              :key="index"
            >
              <div>{{ item.createdTime }}</div>
              <div>
                <div>{{ item.goodsName }}</div>
              </div>
              <div>{{ item.changes }}</div>
              <div>{{item.type===0?'积分商品':'优惠券'}}</div>
              <div :class="{ isGreen: item.state === '已发货' }">
                {{ item.state }}
              </div>
              <div class="company">
                <div
                  v-if="item.logistics.length > 0"
                  v-for="(val, index) in item.logistics"
                  :key="index"
                  class="company-item"
                >
                  <span>{{ val.logisticsCompanyName }}</span>
                  <span style="margin-left: 5px">{{ val.expressNumber }}</span>
                </div>
                <div v-else style="line-height: 40px; margin-left: 20px">-</div>
              </div>
              <div>
                <div
                  class="showCompany"
                >
                  <div v-if="item.type===1" style="line-height: 40px;" @click="linkToCoupon">查看优惠券</div>
                  <div v-else>
                    <span @click="showAddress(item)"  v-track="{event:'查看收货地址',page:'积分中心'}">收货地址</span>
                    <span style="margin-left: 5px" v-if="item.state === '已发货'" @click="getParticulars(item)" v-track="{event:'查看物流',page:'积分中心'}">查看物流</span>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="demo-pagination-block" v-if="recodesObj.total">
          <el-pagination
            :current-page="recodesObj.current"
            page-size="10"
            background
            layout="prev, pager, next, jumper,total"
            :total="recodesObj.total"
            @current-change="handleCurrentChange"
          />
          <el-button class="btn-cl">确定</el-button>
        </div>
      </div>
    </div>

    <!--物流信息弹窗-->
    <el-dialog
      v-model="openfill"
      :destroy-on-close="true"
      title="物流进度"
      width="40%"
    >
      <div class="pack2">
        <div
          :class="packAgeId1 === index ? 'pack2-2' : 'pack2-1'"
          v-for="(item, index) in orderLogisticsPOS"
          :key="index"
          @click="packageclick1(item, index)"
        >
          {{ item.val + item.num }}
        </div>
      </div>
      <div style="margin-bottom: 30px"></div>
      <el-timeline>
        <el-timeline-item
          v-for="(activity, index) in activities"
          :key="index"
          :timestamp="activity.acceptTime"
        >
          {{ activity.acceptStation }}
        </el-timeline-item>
      </el-timeline>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="openfill = false">取消</el-button>
          <el-button type="primary" @click="openfill = false"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
  <address-dialog v-if="isShowAddress" @close="isShowAddress=false" :currentRow="currentRow"></address-dialog>
</template>

<script setup>
// 控制遮罩显示/隐藏
import AddressDialog from './address-dialog.vue'
import { ref, onMounted } from "vue";
import {useRouter} from 'vue-router'
import { pointsRedeemList } from "@/api/points";
import { orderLogistics,orderLogisticsByPage } from "@/api/orderCenter";
import { ElMessage } from "element-plus";
import { getTime } from "@/utils/utils";
import { safeButton } from "@/api/login";


const router=useRouter()
const isVisible = ref(true);
const emits = defineEmits(["close", "find"]);

const recodesArr = ref([]);
const query = ref({
  pageNum: 1,
  pageSize: 10,
});
const recodesObj = ref({});
const options = [
  { value: 0, title: "处理中" },
  { value: 1, title: "已发货" },
];

const getList = () => {
  pointsRedeemList(query.value).then((res) => {
    res.data.list.forEach((v) => {
      v.changes = "-" + v.changes;
      v.state = options.find((o) => o.value === v.state)?.title;
    });

    recodesArr.value = res.data.list;
    recodesObj.value = res.data;
  });
};

//查看物流
const orderLogisticsPOS = ref([]); // 包裹物流信息
const getParticulars = async (item) => {
  let res = await orderLogisticsByPage(item.id);
  if (res.code === 200) {
    orderLogisticsPOS.value = res.data
    orderLogisticsPOS.value.map((item, index) => {
      return Object.assign(item, { num: ++index, val: "包裹" });
    });
    queryExpress(orderLogisticsPOS.value)
  }else{
    ElMessage({
      message:res.message,
      type:'error'
    })
  }

};

const activities = ref([]);
const openfill = ref(false);
const queryExpress = (item) => {
  activities.value = [];
  openfill.value = true;
  let data = {
    logisticsCompanyName: item[0].logisticsCompanyName,
    orderNumber: item[0].orderNumber,
    expressNumber: item[0].expressNumber,
  };
  orderLogistics(data).then((res) => {
    activities.value = res.data.traces.reverse();
  });
};
//选择包裹
const packAgeId1 = ref(0);
const packageclick1 = (val, id) => {
  activities.value = [];
  packAgeId1.value = id;
  let data = {
    logisticsCompanyName: val.logisticsCompanyName,
    expressNumber: val.expressNumber,
    orderNumber: val.orderNumber,
  };
  orderLogistics(data).then((res) => {
    activities.value = res.data.traces.reverse();
  });
};

//收货地址
const currentRow=ref()
const isShowAddress=ref(false)
const showAddress=(item)=>{
  currentRow.value=item
  isShowAddress.value=true
}

//查看优惠券
const linkToCoupon=()=>{
  isVisible.value = false;
  document.body.style.overflow = "";

  router.push({
    path:"/couponList",
    query:{
      index:2
    }
  })
}


// 分页
const handleCurrentChange = (Num) => {
  query.value.pageNum = Num;
  getList();
};

getList();

// 在组件挂载时禁用页面滚动
onMounted(() => {
  document.body.style.overflow = "hidden";
});



// 关闭遮罩函数
const close = () => {
  let timer=Number(localStorage.getItem('buttonTimer'))
  let referer = document.referrer
  if (referer === '') {
    referer = '直接访问'
  } else if (referer.indexOf('?') !== -1) {
    referer = referer.substring(0, referer.indexOf('?'))
  }

  let data = {
    page: '积分中心', //来源页面
    targetPage: '关闭兑换记录', //目标页面
    clickEvent: '关闭兑换记录', //按钮名称
    client: 'PC', //终端
    visitTime: getTime(timer), //访问时间
    source: referer
  }
  safeButton(data)



  isVisible.value = false;
  document.body.style.overflow = "";
  emits("close");
};
</script>

<style scoped lang="less">
.scroll-tabs{
  height: 430px;
  overflow: auto; /* 保持内容可滚动 */
  scrollbar-width: none; /* 对于现代浏览器，隐藏滚动条 */
}
/* 针对IE和Edge的隐藏滚动条样式 */
.scroll-tabs::-webkit-scrollbar {
  display: none;
}

.scroll-tabs {
  -ms-overflow-style: none; /* IE 和 Edge 下隐藏滚动条 */
}
.pack2 {
  display: flex;
  .pack2-1 {
    margin-right: 10px;
    width: 60px;
    height: 26px;
    line-height: 26px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    text-align: center;
    font-weight: 400;
    font-family: Microsoft YaHei;
    font-size: 11px;
    color: #666666;
    cursor: pointer;
  }
  .pack2-2 {
    margin-right: 10px;
    width: 60px;
    height: 26px;
    line-height: 26px;
    border-radius: 5px;
    background: #ce1200;
    color: #ffffff;
    text-align: center;
    font-weight: 400;
    font-family: Microsoft YaHei;
    font-size: 11px;
    cursor: pointer;
  }
}
/* 全屏遮罩 */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* 半透明背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* 弹窗内容 */
.modal-content {
  position: relative;

  .lottery-dialog-box {
    background: url("../img/recodes-bg.png") no-repeat;
    width: 1200px;
    height: 648px;
    background-size: 100%;
    position: relative;

    .close {
      position: absolute;
      width: 24px;
      height: 24px;
      background: url("../img/close.png");
      background-size: 100%;
      top: 12px;
      right: 12px;
      z-index: 1;
      cursor: pointer;
    }

    .title {
      padding: 29px 0 0 36px;
      box-sizing: border-box;
      display: flex;
      align-items: center;

      img {
        width: 40px;
        height: 40px;
      }

      span {
        margin-left: 7px;
        font-size: 24px;
        color: #472300;
      }
    }

    .table-box {
      margin-top: 29px;
      padding: 0 16px 0 32px;
      box-sizing: border-box;

      .table-title {
        min-height: 40px;
        display: flex;
        font-size: 14px;
        color: #928c7f;

        & > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        & > div:nth-child(1) {
          width: 15%;
        }

        & > div:nth-child(2) {
          width: 25%;
          div{
            overflow: hidden; /* 确保超出容器的文本被裁剪 */
            white-space: nowrap; /* 确保文本在一行内显示 */
            text-overflow: ellipsis; /* 使用省略号表示文本溢出 */
          }
        }

        & > div:nth-child(3) {
          width: 10%;
        }

        & > div:nth-child(4) {
          width: 10%;
        }

        & > div:nth-child(5) {
          width: 10%;
        }

        & > div:nth-child(6) {
          width: 15%;
        }
        & > div:nth-child(7) {
          width: 15%;
        }
      }

      .table-content {
        min-height: 40px;
        color: #666666;
        font-size: 14px;

        & > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          border-bottom: 1px solid #fbfafa;
        }

        & > div:nth-child(3) {
          font-weight: bold;
          color: #333333;
        }

        & > div:nth-child(5) {
          color: #ce1200;
        }

        .isGreen {
          color: #04ca17 !important;
        }

        .company {
          //line-height: 20px !important;
          color: #666666 !important;
          font-size: 12px !important;

          .company-item {
            overflow: hidden; /* 确保超出容器的文本被裁剪 */
            white-space: nowrap; /* 确保文本在一行内显示 */
            text-overflow: ellipsis; /* 使用省略号表示文本溢出 */
          }
        }

        .showCompany {
          color: #3491fa;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }

    .demo-pagination-block {
      width: 100%;
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 50px;

      :deep(.is-active){
        background: #ce1200;
      }
      :deep(.is-active:hover){
        color: #FFFFFF !important;
      }
      :deep(.number:hover){
        color: #ce1200;
      }

    }
  }
}

/* 隐藏滚动条，但仍可滚动 */
.scrollable {
  overflow: auto; /* 保持内容可滚动 */
  scrollbar-width: none; /* 对于现代浏览器，隐藏滚动条 */
}

/* 针对IE和Edge的隐藏滚动条样式 */
.scrollable::-webkit-scrollbar {
  display: none;
}

.scrollable {
  -ms-overflow-style: none; /* IE 和 Edge 下隐藏滚动条 */
}
</style>
